import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

const InitialScreenContainer: React.FC = () => {
  return (
    <Grid container justifyContent="center" alignContent="center" css={initialStyled}>
      <Grid item padding={1}>
        <img src="/images/initial-screen-image.png" alt="" />
      </Grid>
    </Grid>
  );
};

export default InitialScreenContainer;

const initialStyled = css`
  z-index: -10;
  background-image: url("/images/blob-scene-haikei.svg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
`;
