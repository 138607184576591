import { APICompsSchema } from "@/apis/api-config";
import { SelectListArray } from "@/utils/app-types";

export const SNACK_BAR_AUTO_HIDE_DURATION_M_SEC = 6000;
export const ELEVATION_APP = 3;

export const GENDER_SELECT_MODEL: Array<{ type: APICompsSchema["EnumGender"]; value: string }> = [
  {
    type: "male",
    value: "男性",
  },
  {
    type: "female",
    value: "女性",
  },
  {
    type: "none",
    value: "無回答",
  },
];

export const MediaContentsTypeModel: Array<{ type: "all" | APICompsSchema["EnumFileType"]; value: string }> = [
  {
    type: "all",
    value: "すべて",
  },
  {
    type: "JPEG",
    value: "JPEG",
  },
  {
    type: "PNG",
    value: "PNG",
  },
  {
    type: "WEBP",
    value: "WEBP",
  },
  {
    type: "MOV",
    value: "MOV",
  },
  {
    type: "MP4",
    value: "MP4",
  },
];

export const JOB_TYPE_SELECT_MODEL: SelectListArray = [
  {
    type: "sales",
    value: "営業",
  },
  {
    type: "planning_and_management",
    value: "企画・経営",
  },
  {
    type: "administration_and_clerical_work",
    value: "管理・事務",
  },
  {
    type: "sales_food_amusement",
    value: "販売・フード・アミューズメント",
  },
  {
    type: "beauty_bridal_hotel_transportation",
    value: "美容・ブライダル・ホテル・交通",
  },
  {
    type: "medical_care_and_welfare",
    value: "医療・福祉",
  },
  {
    type: "childcare_education_interpretation",
    value: "保育・教育・通訳",
  },
  {
    type: "creative",
    value: "クリエイティブ",
  },
  {
    type: "WEB_internet_game",
    value: "WEB・インターネット・ゲーム",
  },
  {
    type: "consultant_finance_real_estate_professional",
    value: "コンサルタント・⾦融・不動産専⾨職",
  },
  {
    type: "public_services",
    value: "公共サービス",
  },

  {
    type: "web_marketing",
    value: "Webマーケティング",
  },
  {
    type: "IT_engineer",
    value: "ITエンジニア",
  },
  {
    type: "electricity_electronics_machinery_semiconductors",
    value: "電気・電⼦・機械・半導体",
  },
  {
    type: "construction_and_building",
    value: "建築・⼟⽊",
  },
  {
    type: "pharmaceuticals_meals_chemicals_materials",
    value: "医薬・⾷品・化学・素材",
  },
  {
    type: "engineering_facilities_distribution_agriculture_forestry_and_water_industry",
    value: "技能⼯・設備・配送・農林⽔産",
  },
];

export const INDUSTRY_CATEGORY_MODEL: SelectListArray = [
  {
    type: "IT_and_Telecommunications",
    value: "IT・通信",
  },
  {
    type: "Internet_Advertising_&_Media",
    value: "インターネット・広告・メディア",
  },
  {
    type: "Manufacturer_(Machinery/Electrical)",
    value: "メーカー(機械・電気)",
  },
  {
    type: "Manufacturer_(Materials_Chemicals_Food_Cosmetics_Others)",
    value: "メーカー(素材・化学・食品・化粧品・その他)",
  },
  {
    type: "trading_company",
    value: "商社",
  },
  {
    type: "Pharmaceuticals_medical_devices_life_sciences_and_medical_services",
    value: "医薬品・医療機器・ライフサイエンス・医療系サービス",
  },
  {
    type: "Financing",
    value: "金融",
  },
  {
    type: "Construction_Plant_&_Real_Estate",
    value: "建設・プラント・不動産",
  },
  {
    type: "Consulting_professional_firms_auditing_firms_tax_accounting_firms_research",
    value: "コンサルティング・専門事務所・監査法人・税理士法人・リサーチ",
  },
  {
    type: "Human_resource_services_outsourcing_call_centers",
    value: "人材サービス・アウトソーシング・コールセンター",
  },
  {
    type: "Retail",
    value: "小売",
  },
  {
    type: "Transportation_&_Logistics",
    value: "運輸・物流",
  },
  {
    type: "Eating_Out",
    value: "外食",
  },
  {
    type: "Energy_(electricity_gas_oil_new_energy)",
    value: "エネルギー(電力・ガス・石油・新エネルギー)",
  },
  {
    type: "Travel_Accommodation_&_Leisure",
    value: "旅行・宿泊・レジャー",
  },
  {
    type: "Security_and_cleaning",
    value: "警備・清掃",
  },
  {
    type: "Hairdressing_beauty_esthetics",
    value: "理容・美容・エステ",
  },
  {
    type: "Education",
    value: "教育",
  },
  {
    type: "Agriculture_Forestry_Fisheries_and_Mining",
    value: "農林水産・鉱業",
  },
  {
    type: "Public_corporations_government_offices,_schools_research_facilities",
    value: "公社・官公庁・学校・研究施設",
  },
  {
    type: "important_ceremonial_occasions_in_family_relationships",
    value: "冠婚葬祭",
  },
  {
    type: "Other",
    value: "その他",
  },
];

export const MEDIA_TYPE_SELECT_MODEL: SelectListArray = [
  {
    type: "All",
    value: "すべてのメディア",
  },
  {
    type: "Image",
    value: "画像",
  },
  {
    type: "Movie",
    value: "動画",
  },
  {
    type: "GIF",
    value: "GIF",
  },
];

export const USER_ROLE_SELECT_MODEL: SelectListArray = [
  {
    type: "OWNER",
    value: "オーナー",
  },
  {
    type: "EDITOR",
    value: "編集者",
  },
  {
    type: "GENERAL",
    value: "一般",
  },
];
