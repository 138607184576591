import { APIOps } from "@/apis/api-config";

import { isStr, zeroPad } from "./common-utils";

export const isDateStr = (o: any) => {
  if (!isStr(o)) return false;
  try {
    const date = new Date(o);
    return Boolean(date.getTime());
  } catch (_) {
    return false;
  }
};

// そのオブジェクトが日付型かどうか判断 => 適当なオブジェクトは入れてはいけない
export const isDate = (v: any): boolean => v && (typeof v === "object" || v instanceof Date);

export const dateOrNull = (v: any): Date | null => (isDateStr(v) || isDate(v) ? new Date(v) : null);

export const dateStrOrNull = (v: any): string | null => {
  const date = dateOrNull(v);
  return date ? date.toISOString() : null;
};

export const dateToLocalDateStr = (v: any): string => {
  const date = dateOrNull(v);
  return date ? date.toLocaleDateString() : "";
};

export const formattedStartDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const formattedStartMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
};

export const formattedEndMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 0, 0, 0, 0, 0);
};

export const startOfThisMonth = () => {
  const now = new Date();
  return formattedStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
};

export const formattedEndDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
};
export const startOfNextMonth = () => {
  const now = new Date();
  return formattedEndDate(new Date(now.getFullYear(), now.getMonth() + 1, 1));
};

export const rangeToMinDate = (range: Array<Date>): Date | null => {
  if (!Array.isArray(range)) return null;
  let minDate = null;
  for (const date of range) {
    if (minDate && minDate.getTime() < date.getTime()) continue;
    minDate = date;
  }
  return minDate;
};

export const rangeToMaxDate = (range: Array<Date>): Date | null => {
  if (!Array.isArray(range)) return null;
  let maxDate = null;
  for (const date of range) {
    if (maxDate && maxDate.getTime() > date.getTime()) continue;
    maxDate = date;
  }
  return maxDate;
};

export const toDateRangeQuery = (start: Date, end: Date) => {
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return { $between: [start.toISOString(), end.toISOString()] };
};

export const formatJpDate = (dt?: Date | null) =>
  dt ? `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1, 2)}-${zeroPad(dt.getDate(), 2)}` : "";

export const formatJpCharDate = (date?: string | number | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};

export const formatJpChartXAxis = (date?: string | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getMonth() + 1}月${zeroPad(dt.getDate(), 2)}日` : "";
};
export const formatAdminChartDate = (date: string | null, dateType: "daily" | "weekly" | "monthly" | "time") => {
  if (!date) return "";
  if (dateType === "daily") {
    const dailyDt = new Date(date);
    return dailyDt ? `${dailyDt.getMonth() + 1}月${zeroPad(dailyDt.getDate(), 2)}日` : "";
  } else if (dateType === "weekly") {
    return date;
  } else if (dateType === "monthly") {
    return date;
  } else if (dateType === "time") {
    const dailyDt = new Date(date);
    return dailyDt ? `${zeroPad(dailyDt.getHours(), 2)}時` : "";
  }
};

export const formatDateOrNow = (date?: string | null): Date => {
  const dt = new Date(date);
  return dt ? dt : new Date();
};
export const formatDateOrNowMinusOne = (date?: string | null): Date => {
  const dt = new Date(date);
  dt.setDate(dt.getDate() - 1);
  return dt ? dt : new Date();
};

export const formatSlashDate = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};
export const formatSlashTime = (date?: string | number | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${zeroPad(dt.getHours(), 2)}:${zeroPad(dt.getMinutes(), 2)}` : "";
};

export const formatSlackDateTime = (date?: string | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt
    ? `${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)} ${zeroPad(dt.getHours(), 2)}:${zeroPad(
        dt.getMinutes(),
        2,
      )}`
    : "";
};

export const formatDateTimeJpy = (date?: string | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt
    ? `${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日 ${zeroPad(dt.getHours(), 2)}:${zeroPad(
        dt.getMinutes(),
        2,
      )}`
    : "";
};
export const formatDateTimeJpyWithYear = (date?: string | Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt
    ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日 ${zeroPad(
        dt.getHours(),
        2,
      )}:${zeroPad(dt.getMinutes(), 2)}`
    : "";
};

// UNIX タイムを算出するよ
export const formatUnixDateTime = (sinceDay: number) => {
  const date = new Date();
  date.setDate(date.getDate() - sinceDay);
  return Math.floor(date.getTime() / 1000);
};

// 07:00:00 => 7時00分
export const formatTimeToJpy = (time: string) => {
  const [h, m] = time.split(":");
  return `${Number(h)}時${Number(m)}分`;
};

// timeFrameを日付範囲の文字列に変換する
// timeFrame: APIOps["get_audience_demographics_api_app__instagram_id__account_insight_audience_demographics_get"]["parameters"]["query"]["time_frame"];
export const timeFrameToDate = (
  timeFrame: APIOps["get_audience_demographics_api_app__instagram_id__account_insight_audience_demographics_get"]["parameters"]["query"]["time_frame"],
) => {
  const now = new Date();
  const start = new Date();
  const end = new Date();
  let jpTxt = "";
  if (timeFrame === "last_14_days") {
    start.setDate(now.getDate() - 14);
    jpTxt = "過去14日間";
  } else if (timeFrame === "last_30_days") {
    start.setDate(now.getDate() - 30);
    jpTxt = "過去30日間";
  } else if (timeFrame === "last_90_days") {
    start.setDate(now.getDate() - 90);
    jpTxt = "過去90日間";
  } else if (timeFrame === "prev_month") {
    start.setMonth(now.getMonth() - 1);
    start.setDate(1);
    end.setDate(0);
    jpTxt = "先月";
  } else if (timeFrame === "this_month") {
    start.setDate(1);
    jpTxt = "今月";
  } else if (timeFrame === "this_week") {
    start.setDate(now.getDate() - now.getDay());
    end.setDate(now.getDate() - now.getDay() + 6);
    jpTxt = "今週";
  }
  return { jpTxt: jpTxt, start: formatJpCharDate(start), end: formatJpCharDate(end) };
};

export const getLastMonthDates = (endAt: string) => {
  const endAtDate = new Date(endAt);
  // endAtの1分前の時刻を計算
  const endAtPrevOneMinute = new Date(endAtDate.getTime() - 60000); // 60000ms = 1 minute

  // endAtの属する月の初日を取得
  const firstDayOfThisMonth = new Date(endAtPrevOneMinute.getFullYear(), endAtPrevOneMinute.getMonth(), 1);

  // endAtの属する月の最後の日を計算
  const firstDayOfNextMonth = new Date(firstDayOfThisMonth.getFullYear(), firstDayOfThisMonth.getMonth() + 1, 1);
  const lastDayThisMonth = new Date(firstDayOfNextMonth.getTime() - 86400000); // 86400000ms = 1 day

  // 先月の最後の日を計算
  const lastDayOfLastMonth = new Date(firstDayOfThisMonth.getTime() - 86400000); // 86400000ms = 1 day

  // 先月の初日を取得
  const firstDayOfLastMonth = new Date(lastDayOfLastMonth.getFullYear(), lastDayOfLastMonth.getMonth(), 1);

  return {
    endAtPrevOneMinute,
    firstDayOfThisMonth,
    lastDayThisMonth,
    lastDayOfLastMonth,
    firstDayOfLastMonth,
  };
};
