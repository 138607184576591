import { jaJP } from "@mui/material/locale";
import { ThemeOptions, createTheme } from "@mui/material/styles";

import { APP_ENV } from "@/global-state/firebase-settings";
import { appColor } from "@/styles/constants/colors";

// Material-UIパーツのテーマ
export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: APP_ENV === "admin" ? appColor.Admin.primary : appColor.App.primary,
    },
    secondary: {
      main: "#788782",
    },
    error: {
      main: "#DE3E44",
    },
    background: {
      default: "#fff",
    },
  },
};

const muiTheme = createTheme(themeOptions, jaJP);

export default muiTheme;
