import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { APICompsSchema } from "@/apis/api-config";
import AppAuthApi from "@/apis/connect/auth-api";
import { DASHBOARD_PATH, LOGIN_PATH, PASSWORD_RESET_PATH, SIGNUP_PATH } from "@/global-state/app-path";
import { ADMIN_LOGIN_PATH } from "@/global-state/external-path";
import { firebaseAuth, firebaseGetAuth } from "@/global-state/firebase-settings";
import {
  authUserAtom,
  idTokenAtom,
  initializingAtom,
  instagramAccountInfoAtom,
  loadingAtom,
  refererAtom,
} from "@/global-state/jotai-atoms";
import { LocalForageKeys } from "@/global-state/local-forage-keys";
import { SignupRequests } from "@/utils/app-types";

import { useSignupReferer } from "../firestore/use-signup-referer";

import {
  EmailAuthProvider,
  FacebookAuthProvider,
  User,
  UserCredential,
  deleteUser,
  linkWithCredential,
  linkWithPopup,
  reauthenticateWithCredential,
  signInWithPopup,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { useAtom } from "jotai";
import localforage from "localforage";
import { useSnackbar } from "notistack";

export const useAuthApi = () => {
  const router = useRouter();
  const [, setLoading] = useAtom(loadingAtom);
  const [, setInitializing] = useAtom(initializingAtom);
  const [instagram, setInstagram] = useAtom(instagramAccountInfoAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [authUser, setAuthUser] = useAtom(authUserAtom);
  const authApi = useMemo(() => new AppAuthApi(idToken, instagram?.instagram_business_id), [idToken, instagram]);

  const [referer] = useAtom(refererAtom);
  const { addSignupReferer } = useSignupReferer();
  const { enqueueSnackbar } = useSnackbar();

  // id, 認証情報リセット => error, logout時に使用
  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(null);
    setLoading(false);
    if (![SIGNUP_PATH, PASSWORD_RESET_PATH].includes(router.pathname)) {
      await router.push(LOGIN_PATH);
    }
  }, []);

  // sync(サービスアクセス時に発火)
  const authSync = useCallback(
    async (refresh: boolean, first?: boolean) => {
      setLoading(true);
      try {
        firebaseAuth.onAuthStateChanged(async (user) => {
          try {
            if (!user) return resetAuth();
            const accessToken = (await user?.getIdToken(true)) || null;
            await setIdToken(accessToken);
            if (!refresh || ["local"].includes(process.env.APP_ENV)) {
              try {
                const memberApi = new AppAuthApi(accessToken);
                const member = await memberApi.memberMe();
                await setAuthUser(member);
                await setInstagram(member.instagram_accounts[0]);
                enqueueSnackbar(`${user?.email + "で" || ""}ログイン中です`, {
                  variant: "success",
                });
                if (first && referer) {
                  try {
                    await addSignupReferer({
                      id: member.id,
                      firebaseAuthId: member.firebase_auth_user_id,
                      refererCode: referer,
                      firstName: member.first_name,
                      lastName: member.last_name,
                      email: member.email,
                      companyName: member.company_name,
                      gender: member.gender,
                      instagramAccountCount: member.instagram_accounts.length,
                      createTime: Date.now(),
                      updateTime: Date.now(),
                    });
                  } catch (e) {
                    console.error(e);
                  }
                }
                if (router.pathname === "/") {
                  await router.push(DASHBOARD_PATH);
                }
              } catch (e) {
                console.error(e?.response?.data?.detail || "", e);
                return resetAuth();
              }
            }
          } catch (e) {
            console.log(e);
          } finally {
            setLoading(false);
            await setTimeout(() => setInitializing(false), 1000);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    [idToken, authUser?.firebase_auth_user_id],
  );

  // FacebookAuth系
  const provider = new FacebookAuthProvider();
  provider.addScope("pages_show_list");
  provider.addScope("instagram_basic");
  provider.addScope("business_management");
  provider.addScope("pages_read_engagement");
  provider.addScope("instagram_manage_insights");
  provider.addScope("instagram_manage_comments");
  if (process.env.APP_ENV !== "production") {
    provider.addScope("instagram_content_publish"); // TODO アプリレビュー前
  }

  // SignUp
  const authFacebookSignUpAndSync = useCallback(
    async (props: SignupRequests) => {
      setLoading(true);
      const facebookSignUpResult = await new Promise<UserCredential>((resolve) => {
        signInWithPopup(firebaseGetAuth, provider)
          .then((result) => resolve(result))
          .catch((error) => {
            const code = error.code;
            if (code === "auth/popup-closed-by-user") {
              enqueueSnackbar("Facebookに正常に接続できませんでした。", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(error?.response?.data?.detail || error?.message, {
                variant: "error",
              });
              return resolve(null);
            }
          });
      });
      if (!facebookSignUpResult) {
        setLoading(false);
        return;
      }
      const user = facebookSignUpResult.user;
      const accessToken = (await user.getIdToken()) || null;
      setIdToken(accessToken);
      const credential = FacebookAuthProvider.credentialFromResult(facebookSignUpResult);
      // Email Linkするよ
      const linkCredential = EmailAuthProvider.credential(props.email, props.password);
      const linkResult = await new Promise<UserCredential>((resolve) => {
        linkWithCredential(firebaseGetAuth.currentUser, linkCredential)
          .then((result) => resolve(result))
          .catch(() => resolve(null));
      });
      if (linkResult) {
        enqueueSnackbar(`Facebook連携が完了しました。Instagramアカウント連携まで少々お待ちください。`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Facebook連携に失敗しました。お手数ですがもう一度お試しください。`, {
          variant: "error",
        });
        await deleteUser(firebaseGetAuth.currentUser);
        setLoading(false);
        return;
      }
      const facebookToken = credential.accessToken;
      try {
        const signUpApi = new AppAuthApi(accessToken);
        await signUpApi.signup({
          firebase_auth_user_id: user.uid,
          email: props.email,
          first_name: props.firstName,
          last_name: props.lastName,
          company_name: props.company,
          job_category: props.jobType,
          industry_category: props.industryCategory,
          birthday: props.birthday,
          gender: props.gender,
          phone_number: props.phoneNumber,
          facebook_id: user.uid,
          facebook_first_token: facebookToken,
          token_status: true,
          is_admin: false,
        });
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
        enqueueSnackbar(`新規登録に失敗しました。右下のお問い合わせフォームからご連絡ください。`, {
          variant: "error",
        });
        setLoading(false);
        return;
      }
      await authSync(false, true);
      setLoading(false);
      return await router.push(DASHBOARD_PATH);
    },
    [authApi],
  );

  // Email&Passwordでのログイン
  const authLoginWithEmail = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      try {
        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then(async (cred) => {
            await localforage.setItem(LocalForageKeys.SigninForm.email, email);
            await localforage.setItem(LocalForageKeys.SigninForm.password, password);
            const linkUser = cred.user;
            const accessToken = (await linkUser.getIdToken()) || null;
            await setIdToken(accessToken);
            try {
              const checkApi = new AppAuthApi(accessToken);
              // await checkApi.checkFirebaseUser();
              const member = await checkApi.memberMe();
              await setAuthUser(member);
              await setInstagram(member.instagram_accounts[0]);
            } catch (e) {
              enqueueSnackbar(e?.response?.data?.detail || e?.message, {
                variant: "error",
              });
              return resetAuth();
            }
            enqueueSnackbar(`${email + "で" || ""}ログイン中です`, {
              variant: "success",
            });
            await router.push(DASHBOARD_PATH);
          })
          .catch((error) => {
            const code = error.code;
            if (code === "auth/user-not-found") {
              enqueueSnackbar("未登録のユーザーです。新規登録をお願いします。", {
                variant: "error",
              });
            } else if (code === "auth/wrong-password") {
              enqueueSnackbar("パスワードが一致しませんでした。", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(error?.response?.data?.detail || error?.message, {
                variant: "error",
              });
            }
            return resetAuth();
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [authApi],
  );

  // Facebookでのログイン
  const authLoginWithFacebook = useCallback(async () => {
    setLoading(true);
    try {
      signInWithPopup(firebaseGetAuth, provider)
        .then(async (cred) => {
          const linkUser = cred.user;
          const accessToken = (await linkUser.getIdToken()) || null;
          try {
            const checkApi = new AppAuthApi(accessToken);
            // await checkApi.checkFirebaseUser();
            await setIdToken(accessToken);
            const member = await checkApi.memberMe();
            await setAuthUser(member);
            await setInstagram(member.instagram_accounts[0]);
          } catch (e) {
            await enqueueSnackbar(e?.response?.data?.detail || e?.message, {
              variant: "error",
            });
            return resetAuth();
          }
          if (process.env.APP_ENV === "admin") {
            return await router.push(ADMIN_LOGIN_PATH);
          } else {
            enqueueSnackbar(`Facebookでログイン中です`, {
              variant: "success",
            });
            await router.push(DASHBOARD_PATH);
          }
        })
        .catch(async (error) => {
          const code = error.code;
          if (code === "auth/popup-closed-by-user") {
            enqueueSnackbar("Facebookに正常に接続できませんでした。", {
              variant: "error",
            });
          } else if (code === "auth/user-not-found") {
            enqueueSnackbar("未登録のユーザーです。新規登録をお願いします。", {
              variant: "error",
            });
          } else {
            enqueueSnackbar(error?.message || "error", {
              variant: "error",
            });
          }
          return resetAuth();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  }, [authApi]);

  // ログアウト
  const authLogout = useCallback(async () => {
    setLoading(true);
    try {
      firebaseAuth
        .signOut()
        .then(async () => {
          enqueueSnackbar("ログアウトしました。", {
            variant: "info",
          });
          await router.push(LOGIN_PATH);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message || "error", {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  }, [authApi]);

  // 会員情報のアップデート
  const upDateMemberMe = useCallback(
    async (props: APICompsSchema["UpdateMemberRequest"]) => {
      setLoading(true);
      try {
        await authApi.upDateMember(props);
        enqueueSnackbar("会員情報の変更が完了しました。", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [authApi],
  );

  // Facebook再連携に使用するやつ
  const upDateFacebookReLinked = useCallback(async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(firebaseGetAuth, provider);
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const linkUser = result.user;
      const accessToken = (await linkUser.getIdToken()) || null;

      const facebookToken = credential.accessToken;
      await authApi.upDateInstagramAccounts(facebookToken);
      await authApi.upDateMember({
        email: authUser.email,
        first_name: authUser.first_name,
        last_name: authUser.last_name,
        company_name: authUser.company_name,
        job_category: authUser.job_category,
        industry_category: authUser.industry_category,
        birthday: authUser.birthday,
        gender: authUser.gender,
        phone_number: authUser.phone_number,
        token_status: true,
      });
      try {
        const memberApi = new AppAuthApi(accessToken);
        const member = await memberApi.memberMe();
        await setAuthUser(member);
        await setInstagram(member.instagram_accounts[0]);
        await enqueueSnackbar("Facebookの再連携が完了しました。", {
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("HINOMEの登録に使用しているFacebookアカウントではありません。", {
          variant: "error",
        });
        return resetAuth();
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e?.response?.data?.detail || e?.message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [authApi]);

  const putUpdateFavoriteInstagram = useCallback(
    async (instagram_account_id: string) => {
      setLoading(true);
      try {
        return await authApi.upDateFavoriteInstagram(instagram_account_id);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [authApi],
  );

  const deleteInstagramAccountTunnel = useCallback(
    async (instagram_account_id: string) => {
      setLoading(true);
      try {
        await authApi.deleteInstagramAccount(instagram_account_id);
        enqueueSnackbar("連携解除に成功しました", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [authApi],
  );

  //パスワードリセット w/email
  const resetPasswordWithEmail = useCallback(async (email: string) => {
    setLoading(true);
    await firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        enqueueSnackbar("パスワード再設定メールを送信しました", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        const code = error.code;
        if (code === "auth/user-not-found") {
          enqueueSnackbar("ユーザーが見つかりませんでした。", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("メールの送信に失敗しました", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //パスワードリセット
  const resetPassword = useCallback(async (user: User, prevPassword: string, newPassword: string) => {
    setLoading(true);
    const credential = await EmailAuthProvider.credential(user.email, prevPassword);
    try {
      await reauthenticateWithCredential(user, credential).then(async (cred) => {
        await updatePassword(cred.user, newPassword)
          .then(() => {
            enqueueSnackbar("パスワードの更新に成功しました。", {
              variant: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            const code = error.code;
            if (code) {
              enqueueSnackbar("パスワードのリセットに失敗しました。お問い合わせお願い致します。", {
                variant: "error",
              });
            }
          })
          .finally(() => setLoading(false));
      });
    } catch (error) {
      const code = error.code;
      if (code === "auth/wrong-password") {
        enqueueSnackbar("現在のパスワードが一致しませんでした。再度やり直してください。", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("パスワードのリセットに失敗しました。お問い合わせお願い致します。", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const resetEmail = useCallback(async (email: string, password: string, newEmail: string) => {
    const user = firebaseGetAuth.currentUser;
    setLoading(true);
    const credential = await EmailAuthProvider.credential(email, password);
    try {
      await reauthenticateWithCredential(user, credential).then(async (cred) => {
        await updateEmail(cred.user, newEmail)
          .then(async () => {
            enqueueSnackbar("メールアドレスの更新に成功しました。", {
              variant: "success",
            });
            const props: APICompsSchema["UpdateMemberRequest"] = {
              email: newEmail,
              first_name: authUser.first_name,
              last_name: authUser.last_name,
              company_name: authUser.company_name,
              job_category: authUser.job_category,
              industry_category: authUser.industry_category,
              birthday: authUser.birthday,
              gender: authUser.gender,
              phone_number: authUser.phone_number,
              token_status: authUser.token_status,
            };
            await authApi.upDateMember(props);
          })
          .catch(async (error) => {
            console.log(error);
            const code = error.code;
            if (code) {
              enqueueSnackbar("メールアドレスの更新に失敗しました。お問い合わせお願い致します。", {
                variant: "error",
              });
            }
            const props: APICompsSchema["UpdateMemberRequest"] = {
              email: email,
              first_name: authUser.first_name,
              last_name: authUser.last_name,
              company_name: authUser.company_name,
              job_category: authUser.job_category,
              industry_category: authUser.industry_category,
              birthday: authUser.birthday,
              gender: authUser.gender,
              phone_number: authUser.phone_number,
              token_status: authUser.token_status,
            };
            await authApi.upDateMember(props);
          })
          .finally(() => setLoading(false));
      });
    } catch (error) {
      const code = error.code;
      if (code === "auth/wrong-password") {
        enqueueSnackbar("現在のパスワードが一致しませんでした。再度やり直してください。", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("メールアドレスの更新に失敗しました。お問い合わせお願い致します。", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const linkWithPopupFacebook = useCallback(async () => {
    setLoading(true);
    const result = await new Promise<UserCredential>((resolve) => {
      linkWithPopup(firebaseGetAuth.currentUser, provider)
        .then((response) => resolve(response))
        .catch((error) => {
          const code = error.code;
          if (code === "auth/popup-closed-by-user") {
            enqueueSnackbar("Facebookに正常に接続できませんでした。", {
              variant: "error",
            });
          } else if (code === "auth/credential-already-in-use") {
            enqueueSnackbar(
              "Facebookアカウントが別のアカウントに紐づいています。恐れ入りますが一度ログアウトした後、「Facebookでログイン」をお試しください。",
              {
                variant: "error",
              },
            );
          } else {
            enqueueSnackbar(error?.response?.data?.detail || error?.message, {
              variant: "error",
            });
            return resolve(null);
          }
        });
    });
    if (!result) {
      setLoading(false);
      return;
    }
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const linkUser = result.user;
    const accessToken = (await linkUser.getIdToken()) || null;
    const facebookToken = credential.accessToken;
    await authApi.upDateInstagramAccounts(facebookToken);
    await authApi.upDateMember({
      email: authUser.email,
      first_name: authUser.first_name,
      last_name: authUser.last_name,
      company_name: authUser.company_name,
      job_category: authUser.job_category,
      industry_category: authUser.industry_category,
      birthday: authUser.birthday,
      gender: authUser.gender,
      phone_number: authUser.phone_number,
      token_status: true,
    });
    try {
      const memberApi = new AppAuthApi(accessToken);
      const member = await memberApi.memberMe();
      await setAuthUser(member);
      await setInstagram(member.instagram_accounts[0]);
      await enqueueSnackbar("Facebookの再連携が完了しました。", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("HINOMEの登録に使用しているFacebookアカウントではありません。", {
        variant: "error",
      });
      return resetAuth();
    }
  }, []);

  return {
    authSync,
    authFacebookSignUpAndSync,
    authLoginWithEmail,
    authLoginWithFacebook,
    authLogout,
    upDateMemberMe,
    upDateFacebookReLinked,
    putUpdateFavoriteInstagram,
    deleteInstagramAccountTunnel,
    resetPasswordWithEmail,
    resetPassword,
    resetEmail,
    linkWithPopupFacebook,
  };
};
