import React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useCustomMediaQuery } from "@/custom-hooks/use-custom-media";

const QueryProgress: React.FC = () => {
  const isMobile = !useCustomMediaQuery("sm");
  return (
    <Grid container justifyContent="center" alignItems="center" p={5} pt={isMobile ? 12 : 5}>
      <CircularProgress />
    </Grid>
  );
};
export default QueryProgress;
