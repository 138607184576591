import { useMediaQuery, useTheme } from "@mui/material";

import { MuiMediaQuery } from "@/utils/app-types";

export const useCustomMediaQuery = (props: MuiMediaQuery) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(props));
};

export const useBetweenMediaQuery = (min: MuiMediaQuery, max: MuiMediaQuery) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(min, max));
};
