import { EmotionCache, Global } from "@emotion/react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { useCustomMediaQuery } from "@/custom-hooks/use-custom-media";
import { SNACK_BAR_AUTO_HIDE_DURATION_M_SEC } from "@/global-state/app-config";
import { firebaseAuth } from "@/global-state/firebase-settings";
import { globalStyled } from "@/styles/emotion-global";
import muiTheme from "@/styles/mui-theme";
import { bool } from "@/utils/common-utils";
import { GoogleAnalytics, usePageView } from "@/utils/gtag";

import AppSEO from "@/components/common/AppSEO";
import AppZendesk from "@/components/common/AppZendesk";
import SnackbarCloseButton from "@/components/layout/SnackBarCloseButton";
import AuthProvider from "@/components/provider/AuthProvider";
import { GrowthBook } from "@growthbook/growthbook-react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { Provider } from "jotai";
import { SnackbarProvider } from "notistack";
import "react-device-frameset/styles/marvel-devices.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "rsuite/dist/rsuite.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    return;
  },
});

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: process.env.APP_ENV === "admin" ? 900000 : 30000,
      keepPreviousData: true,
    },
  },
});
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  usePageView();
  const { Component, pageProps } = props;
  const isMobile = !useCustomMediaQuery("sm");

  React.useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  React.useEffect(() => {
    // Load features asynchronously when the app renders
    if (firebaseAuth.currentUser) {
      growthbook.setAttributes({
        id: firebaseAuth.currentUser.uid,
      });
    }
  }, [firebaseAuth.currentUser]);

  const render = () => {
    return (
      <main>
        <Layout noHeader={bool(pageProps.noHeader)}>
          <Component {...pageProps} />
        </Layout>
      </main>
    );
  };

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <ThemeProvider theme={muiTheme}>
            <SnackbarProvider
              autoHideDuration={SNACK_BAR_AUTO_HIDE_DURATION_M_SEC}
              anchorOrigin={{ horizontal: isMobile ? "right" : "center", vertical: isMobile ? "top" : "bottom" }}
              maxSnack={3}
              preventDuplicate
              action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              <CssBaseline />
              <Global styles={globalStyled} />
              <AuthProvider>{render()}</AuthProvider>
              {process.env.APP_ENV === "production" && (
                <>
                  <AppZendesk />
                  <AppSEO />
                  <GoogleAnalytics />
                </>
              )}
              {process.env.APP_ENV === "staging" && (
                <>
                  <AppZendesk />
                  <AppSEO />
                  <GoogleAnalytics />
                </>
              )}
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </GrowthBookProvider>
  );
}
