import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

import { SnackbarKey, useSnackbar } from "notistack";

type Props = {
  snackbarKey: SnackbarKey;
};
const SnackbarCloseButton: React.FC<Props> = (props) => {
  const { closeSnackbar } = useSnackbar();
  const onClickSnackbar = () => closeSnackbar(props.snackbarKey);
  return (
    <IconButton onClick={onClickSnackbar} color={"inherit"} size={"small"}>
      <CloseIcon color="inherit" />
    </IconButton>
  );
};

export default SnackbarCloseButton;
