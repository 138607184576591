export const appColor = {
  App: {
    primary: "#40967B",
    accent: "#DE3E44",
    white: "#FFFFFF",
  },
  Admin: {
    primary: "#96866F",
    activeSidenav: "#F1EFED",
    textPrimary: "#372E1F",
    textSecondary: "#605B52",
    textNegative: "#878178",
    pieChart: ["#96866F", "#a1927d", "#ab9e8c", "#b6aa9a", "#c0b6a9", "#cbc3b7", "#d5cfc5", "#e0dbd4"],
  },
  text: {
    primary: "#1F3730",
    secondary: "#52605C",
    Negative: "#788782",
    PrimaryAccent: "#40967B",
    Link: "#009BCC",
    white: "#FFFFFF",
  },
  border: {
    primary: "rgba(64, 150, 123, 0.04)",
    secondary: "#E2E4E3",
    negative: "#CBD5E0",
  },
  background: {
    primary: "#FAFAFA",
    secondary: "#FFFFFF",
    negative: "#F7FAFC",
    sidenav: "#ECF5F2",
    innerSideNac: "#f4f5f5",
    activeSidenav: "rgba(64, 150, 123, 0.08)",
    dialogTooltips: "rgba(64, 150, 123, 0.9)",
    hashtag: "#ECF5F2",
    downloadChip: "rgba(64, 150, 123, 0.75)",
    gray: "#605B52",
    graySoft: "#d8d6d4",
    error: "#FCE4EC",
  },
  button: {
    bgPrimary: "#40967B",
    typoPrimary: "#FFFFFF",
    bgSecondary: "#DE3E44",
    bgNegative: "#D5D8D7",
    bgSidenav: "rgba(64, 150, 123, 0.08)",
    facebook: "#1877F2",
  },
  chart: {
    dot: "#FFFFFF",

    barAgeGenderF: "#FF80AB",
    barAgeGenderM: "#82B1FF",
    barAgeGenderU: "#DDDDDD",

    barFollowerRiseFall: "#A0CBBD",
    lineFollowerAndReachChart: "#E45328",

    areaProfile1: "#FF9800",
    areaProfile2: "#03A9F4",
    areaProfile3: "#FFEB3B",
    areaProfile4: "#673AB7",
    areaProfile5: "#E91E63",
    areaProfile6: "#009688",
    areaProfile7: "#FF5722",
    areaProfile8: "#607D8B",
    areaProfile9: "#9C27B0",
    areaProfile10: "#4CAF50",
    audienceCity: ["#82B1FF", "#FF80AB", "#DDDDDD"],
    audienceOnline: ["#40967B", "#60A891", "#80B9A7", "#A0CBBD", "#BFDCD3", "#ecf5f2", "#FAFCFB", "#F1F1F1"],

    groupHashtagBar: "#C6E0D7",
  },
  chip: {
    rank1: "#D1B000",
    rank2: "#A9A9A9",
    rank3: "#A36B21",
    rankDefault: "#FAFAFA",
  },
  countryChart: {
    colorAxis: ["#d9eae5", "#c6e0d7", "#b3d5ca", "#a0cbbd", "#8cc0b0", "#79b6a3", "#66ab95", "#53a188", "#40967B"],
    backgroundColor: "#fefefe",
    datalessRegionColor: "#F1F1F1",
  },
  wordCloud: ["#265a4a", "#337862", "#3a876f", "#40967b", "#53a188", "#66ab95", "#79b6a3"],
} as const;
