export const LocalForageKeys = {
  SignupQuestion: {
    key: "SignupQuestion",
    read: "read",
  },
  SigninForm: {
    email: "82244417f956ac7c599f191593f7e441a4fafa20a4158fd52e154f1dc4c8ed92",
    password: "5e884898da28047151d0e56f8dc6292773603d0d6aabbdd62a11ef721d1542d8",
  },
  LoginDialog: {
    TrialBannerDialog: "TrialBannerDialog3",
  },
  useInstagramQuestion: {
    key: "useInstagramQuestion",
  },
} as const;
