import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { useAdminApi } from "@/custom-hooks/apis/use-admin-api";
import { useAuthApi } from "@/custom-hooks/apis/use-auth-api";
import { LOGIN_PATH, PASSWORD_RESET_PATH, SIGNUP_PATH } from "@/global-state/app-path";
import { ADMIN_LOGIN_PATH, CORPORATE_LOGIN_PATH } from "@/global-state/external-path";
import { authUserAtom, idTokenAtom, initializingAtom, refererAtom } from "@/global-state/jotai-atoms";

import InitialScreenContainer from "@/components/common/InitialScreenContainer";
import QueryProgress from "@/components/common/QueryProgress";
import BackdropProgress from "@/components/provider/BackdropProgress";
import { useAtom, useSetAtom } from "jotai";

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = (props) => {
  const router = useRouter();
  const setReferer = useSetAtom(refererAtom);
  const referer = router.query.referer || router.query.referrer || router.query.ref;

  const [initializing, setInitializing] = useAtom(initializingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const [authUser] = useAtom(authUserAtom);
  const { authSync } = useAuthApi();
  const { adminAuthSync } = useAdminApi();

  // NOTE: 初回呼び出し
  useEffect(() => {
    (async () => {
      if (process.env.APP_ENV === "corporate") {
        await router.push(CORPORATE_LOGIN_PATH);
        await setInitializing(false);
        return;
      }
      if (process.env.APP_ENV === "admin") {
        if (process.env.DEV_ENV === "admin-local") {
          await adminAuthSync();
        }
        await router.push(ADMIN_LOGIN_PATH);
        await setInitializing(false);
      } else {
        if (initializing) {
          await authSync(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (authUser && idToken) {
      const timeout = setTimeout(() => authSync(true), 1000 * 60 * 5);
      return () => clearTimeout(timeout);
    }
  }, [idToken, authUser?.firebase_auth_user_id]);

  useEffect(() => {
    if (!["corporate", "admin", "local"].includes(process.env.APP_ENV)) {
      (async () => {
        if (![SIGNUP_PATH, PASSWORD_RESET_PATH].includes(router.pathname)) {
          setReferer(referer as string);
          await router.push(LOGIN_PATH);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (referer) {
      setReferer(referer as string);
    }
  }, [referer]);

  if (initializing) {
    return (
      <BackdropProgress open>
        <InitialScreenContainer />
      </BackdropProgress>
    );
  }

  if (process.env.APP_ENV === "admin" && authUser?.id && !authUser?.is_admin) {
    return <QueryProgress />;
  }

  return <>{props.children}</>;
};

export default AuthProvider;
