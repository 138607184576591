import { APICompsSchema, APIOps } from "@/apis/api-config";
import { AppCalendarEventType } from "@/utils/app-types";
import { formatJpDate } from "@/utils/time-utils";

import { addDays, addMonths } from "date-fns";
import { atom } from "jotai";
import { PrimitiveAtom } from "jotai/core/atom";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const idTokenAtom = atom<string | null>(null) as PrimitiveAtom<string | null>;

export const authUserAtom = atom<APICompsSchema["MemberResponse"] | null>(null) as PrimitiveAtom<
  APICompsSchema["MemberResponse"] | null
>;
export const instagramAccountInfoAtom = atom<APICompsSchema["InstagramAccountWithSubscriptionSchema"] | null>(
  null,
) as PrimitiveAtom<APICompsSchema["InstagramAccountWithSubscriptionSchema"] | null>;
export const organizationAtom = atom<APICompsSchema["OrganizationSchema"] | null>(null) as PrimitiveAtom<
  APICompsSchema["OrganizationSchema"] | null
>;

export const refererAtom = atom<string>("");
export const pageTitleAtom = atom<string>("");
export const goBackPathAtom = atom<string>("/");
export const dateRangeAtom = atom<[Date, Date]>([addDays(addMonths(new Date(), -1), -1), addDays(new Date(), -1)]);
export const facebookPermissionsAtom = atom<Array<string>>([]);

export const contentsMediaLabelsAtom = atom<APICompsSchema["MediaLabelsSchema"][]>([]);
export const corpInstagramLabelsAtom = atom<APICompsSchema["InstagramAccountLabelsSchema"][]>([]);

export const croppedImgSrcAtom = atom<string>("");

export const selectedMediaAtom = atom<APICompsSchema["GetMediaContentsResponse"][]>([]);
export const selectedScheduledMediaAtom = atom<APICompsSchema["GetScheduledPostsResponse"] | null>(
  null,
) as PrimitiveAtom<APICompsSchema["GetScheduledPostsResponse"] | null>;

export const calendarCurrentEventsAtom = atom<AppCalendarEventType[]>([]);

// for admin dialog
export const forAdminSetPlanDialogAtom = atom<{
  open: boolean;
  obj: APICompsSchema["InstagramAccountWithSubscriptionSchema"] | null;
}>({
  open: false,
  obj: null,
});

export const forAdminSetInstagramTableContainerAtom = atom<
  APIOps["get_admin_instagram_table_api_admin_instagram_table_get"]["parameters"]["query"]
>({
  order_by: "created_at",
  order_by_asc_desc: "desc",
});

export const forAdminSetUserTableContainerAtom = atom<
  APIOps["get_admin_user_table_api_admin_user_table_get"]["parameters"]["query"]
>({
  order_by: "created_at",
  order_by_asc_desc: "desc",
});

// for corporate dialog
const lastMonthFirstDay = addMonths(new Date(), -1);
const lastMonthFirstDayDate = new Date(lastMonthFirstDay.getFullYear(), lastMonthFirstDay.getMonth(), 1);
const lastMonthLastDayDate = new Date(lastMonthFirstDay.getFullYear(), lastMonthFirstDay.getMonth() + 1, 0);
export const forCorpSetInstagramTableContainerAtom = atom<
  APIOps["get_instagram_table_for_corp_api_corporate_instagram_account_list_get"]["parameters"]["query"]
>({
  created_at_start: formatJpDate(lastMonthFirstDayDate),
  created_at_end: formatJpDate(lastMonthLastDayDate),
  order_by: "created_at",
  order_by_asc_desc: "desc",
});

export const selectedInstagramAccountIdsAtom = atom<string[]>([]);
