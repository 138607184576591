import BaseApi from "@/apis/base-api";

export default class ApiPaths extends BaseApi {
  protected toAdminURL(path: string) {
    return `/api/admin/${path}`;
  }
  protected toAdminCorporateURL(path: string) {
    return `/api/admin/corporate${path}`;
  }
  protected toCorporateURL(path: string) {
    return `/api/corporate${path}`;
  }
  protected toCorporateAccountLabelURL(path: string) {
    return `/api/corporate/account_label${path}`;
  }
  protected toAuthURL(path: string) {
    return `/api/app/auth/${path}`;
  }
  protected toMemberURL(path: string) {
    return `/api/app/member/${path}`;
  }
  protected toAccountInsightURL(path: string) {
    return `/api/app/${this.instagramId}/account-insight/${path}`;
  }
  protected toMyAccountURL(path: string) {
    return `/api/app/${this.instagramId}/${path}`;
  }
  protected toFeedURL(feedId: string, path: string) {
    return `/api/app/${feedId}/${path}`;
  }
  protected toReelURL(reelId: string, path: string) {
    return `/api/app/${reelId}/${path}`;
  }
  protected toStoryURL(storyId: string, path: string) {
    return `/api/app/${storyId}/${path}`;
  }
  protected toBenchmarkURL(path: string) {
    return `/api/app/benchmark/${this.instagramId}/${path}`;
  }
  protected toHashtagURL(path: string) {
    return `/api/app/${this.instagramId}/hashtag/${path}`;
  }
  protected toReportURL(path: string) {
    return `/api/app/report/${this.instagramId}/${path}`;
  }
  protected toExSuggestURL(path: string) {
    return `/api/app/${this.instagramId}/ex-suggest/${path}`;
  }
  protected toContentsLibraryUrl(path: string) {
    return `/api/app/${this.instagramId}/contents-library${path}`;
  }
  protected toScheduledPostUrl(path: string) {
    return `/api/app/${this.instagramId}/scheduled-post${path}`;
  }
  protected toExApiURL(path: string) {
    return `/api/connect/ex-apis/${path}`;
  }
}
