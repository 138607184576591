import { APICompsSchema } from "@/apis/api-config";

import { ExportToCsv } from "export-to-csv";

const sleepByPromise = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec * 1000));

export const sleep = async (sec: number) => await sleepByPromise(sec);

export const int = (v: any): number => (v && parseInt(v)) || 0;

export const zeroPad = (num: number, length: number) => ("0000000000" + int(num)).slice(-int(length));

export const forceInt = (v: any): number => int(str(v).replace(/[^0-9]/g, ""));

export const float = (v: any): number => (v && parseFloat(v)) || 0;

export const isStr = (v: any): boolean => Boolean(v && (typeof v === "string" || v instanceof String));

export const getVersion = (): number => new Date().getTime();

export const isNum = (v?: any): boolean => !isNaN(parseInt(v));

export const str = (v: any): string => (v ? String(v) : "");

export const bool = (v: any): boolean => Boolean(v);

export const arr2Len = (arr: Array<any>): number => (Array.isArray(arr) ? arr.length : 0);

export const hasTowElem = (arr: Array<any>): boolean => arr2Len(arr) === 2;

export const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const intLocalStr = (v: any) => int(v).toLocaleString();

export const floatLocalStr = (v: any) => float(v).toFixed(2);

export const numberFieldVal = (v: any): "" | number => (isNum(v) ? int(v) : "");

export const getPercent = (numerator: number, denominator: number): number => {
  if (!numerator || !denominator) {
    return 0.0;
  }
  return parseFloat(((numerator / denominator) * 100).toFixed(1)) || 0.0;
};

export const exportToCsv = (filenamePrefix: string, objects: any[]) => {
  const filename = `${filenamePrefix}${new Date().toLocaleDateString().replace(/\//g, "_")}`;
  const csvExporter = new ExportToCsv({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename,
  });
  csvExporter.generateCsv(objects);
};

export const genderKeyToName = (key: APICompsSchema["EnumGender"]) => {
  if (key === "male") return "男性";
  if (key === "female") return "女性";
  return "未設定";
};

export const shuffleArray = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const toCamelCase = (text: string) =>
  str(text)
    .split("_")
    .map((word, index) => {
      if (index === 0) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");

export const toSnakeCase = (text: string) =>
  str(text)
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();

export const hiraganaToKatakana = (text: string) =>
  text.replace(/[\u3041-\u3096]/g, (match) => String.fromCharCode(match.charCodeAt(0) + 0x60));

export const katakanaToHiragana = (text: string) =>
  text.replace(/[\u30a1-\u30f6]/g, (match) => String.fromCharCode(match.charCodeAt(0) - 0x60));

export const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
  event.returnValue = "";
};
