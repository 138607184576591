import { APIOps } from "@/apis/api-config";
import ApiPaths from "@/apis/base-api-paths";

export default class AppAuthApi extends ApiPaths {
  async memberMe(): Promise<
    APIOps["member_info_api_app_auth_member_me_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toAuthURL("member-me"))).data;
  }
  async upDateMember(
    props: APIOps["update_member_info_api_app_auth_member_me_put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIOps["update_member_info_api_app_auth_member_me_put"]["responses"]["201"]["content"]["application/json"]
  > {
    return (await this.put(this.toAuthURL("member-me"), props)).data;
  }
  async signup(
    props: APIOps["signup_api_app_auth_signup_post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIOps["signup_api_app_auth_signup_post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post(this.toAuthURL("signup"), props)).data;
  }
  async checkFirebaseUser(): Promise<
    APIOps["check_firebase_user_api_app_auth_check_firebase_user_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toAuthURL("check-firebase-user"))).data;
  }
  async upDateInstagramAccounts(
    facebook_first_token: APIOps["update_instagram_info_api_app_auth_instagram_me_get"]["parameters"]["query"]["facebook_first_token"],
  ): Promise<
    APIOps["update_instagram_info_api_app_auth_instagram_me_get"]["responses"]["201"]["content"]["application/json"]
  > {
    return (await this.get(this.toAuthURL(`instagram-me?facebook_first_token=${facebook_first_token}`))).data;
  }
  async deleteInstagramAccount(
    instagram_account_id: APIOps["delete_my_instagram_tunnel_api_app_auth_instagram_me_delete"]["parameters"]["query"]["instagram_account_id"],
  ): Promise<
    APIOps["delete_my_instagram_tunnel_api_app_auth_instagram_me_delete"]["responses"]["204"]["content"]["application/json"]
  > {
    return await this.delete(this.toAuthURL(`instagram-me?instagram_account_id=${instagram_account_id}`));
  }
  async upDateFavoriteInstagram(
    instagram_account_id: APIOps["update_favorite_instagram_account_api_app_auth_member_favorite_instagram_put"]["parameters"]["query"]["instagram_account_id"],
  ): Promise<
    APIOps["update_favorite_instagram_account_api_app_auth_member_favorite_instagram_put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(this.toAuthURL(`member-favorite-instagram?instagram_account_id=${instagram_account_id}`)))
      .data;
  }
}
