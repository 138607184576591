import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { APIOps } from "@/apis/api-config";
import AdminApi from "@/apis/connect/admin-api";
import AppAuthApi from "@/apis/connect/auth-api";
import { ADMIN_DASHBOARD_PATH, ADMIN_LOGIN_PATH } from "@/global-state/external-path";
import { GOOGLE_AUTHENTICATOR_TOKEN, firebaseAuth } from "@/global-state/firebase-settings";
import { authUserAtom, idTokenAtom, instagramAccountInfoAtom, loadingAtom } from "@/global-state/jotai-atoms";

import speakeasy from "@levminer/speakeasy";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";

export const useAdminApi = () => {
  const router = useRouter();
  const [, setLoading] = useAtom(loadingAtom);
  const [instagram, setInstagram] = useAtom(instagramAccountInfoAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [authUser, setAuthUser] = useAtom(authUserAtom);
  const adminAuthApi = useMemo(() => new AppAuthApi(idToken, instagram?.instagram_business_id), [idToken, instagram]);
  const adminAppApi = useMemo(() => new AdminApi(idToken, instagram?.instagram_business_id), [idToken, instagram]);

  const { enqueueSnackbar } = useSnackbar();

  // id, 認証情報リセット => error, logout時に使用
  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(null);
    setLoading(false);
    await router.push(ADMIN_LOGIN_PATH);
  }, []);

  // sync(サービスアクセス時に発火)
  const adminAuthSync = useCallback(async () => {
    setLoading(true);
    try {
      firebaseAuth.onAuthStateChanged(async (user) => {
        if (!user) return resetAuth();
        const accessToken = (await user?.getIdToken(true)) || null;
        await setIdToken(accessToken);
        try {
          const memberApi = new AppAuthApi(accessToken);
          const member = await memberApi.memberMe();
          if (!member.is_admin) {
            enqueueSnackbar("管理者ではありません", {
              variant: "error",
            });
            return resetAuth();
          } else {
            await setAuthUser(member);
            await setInstagram(member.instagram_accounts[0]);
            enqueueSnackbar("HINOME管理者ダッシュボードにログインしました", {
              variant: "success",
            });
          }
        } catch (e) {
          return resetAuth();
        }
        await router.push(ADMIN_DASHBOARD_PATH);
      });
    } catch (e) {
      return resetAuth();
    } finally {
      setLoading(false);
    }
  }, [idToken, authUser?.firebase_auth_user_id]);

  // Email&Password&GoogleAuthenticatorCodeでのログイン
  const adminLogin = useCallback(
    async (email: string, password: string, authCode: string) => {
      setLoading(true);
      try {
        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then(async (cred) => {
            if (
              !speakeasy.totp.verify({
                secret: GOOGLE_AUTHENTICATOR_TOKEN,
                encoding: "base32",
                token: authCode,
              })
            ) {
              return enqueueSnackbar("AuthCodeが間違っています", {
                variant: "error",
              });
            } else {
              enqueueSnackbar("HINOME管理者ダッシュボードにログインしました", {
                variant: "success",
              });
            }
            const linkUser = cred.user;
            const accessToken = (await linkUser.getIdToken()) || null;
            await setIdToken(accessToken);
            try {
              const checkApi = new AppAuthApi(accessToken);
              const member = await checkApi.memberMe();
              if (!member.is_admin) {
                enqueueSnackbar("管理者ではありません。", {
                  variant: "error",
                });
                return resetAuth();
              } else {
                await setAuthUser(member);
                await setInstagram(member.instagram_accounts[0]);
              }
            } catch (e) {
              console.log(e, e?.response?.data?.detail);
              return resetAuth();
            }
            await router.push(ADMIN_DASHBOARD_PATH);
          })
          .catch((error) => {
            const code = error.code;
            if (code === "auth/user-not-found") {
              enqueueSnackbar("未登録のユーザーです。新規登録をお願いします。", {
                variant: "error",
              });
            } else if (code === "auth/wrong-password") {
              enqueueSnackbar("パスワードが一致しませんでした。", {
                variant: "error",
              });
            } else {
              console.log(error);
              enqueueSnackbar(error?.response?.data?.detail || error?.message, {
                variant: "error",
              });
            }
            return resetAuth();
          });
      } catch (e) {
        console.log(e, e?.response?.data?.detail);
        return resetAuth();
      } finally {
        setLoading(false);
      }
    },
    [adminAuthApi],
  );

  // ログアウト
  const adminLogout = useCallback(async () => {
    setLoading(true);
    try {
      firebaseAuth
        .signOut()
        .then(async () => {
          enqueueSnackbar("ログアウトしました。", {
            variant: "info",
          });
          await router.push(ADMIN_LOGIN_PATH);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message || "error", {
            variant: "error",
          });
        });
    } catch (e) {
      console.log(e, e?.response?.data?.detail);
      return resetAuth();
    } finally {
      await resetAuth();
      setLoading(false);
    }
  }, [adminAuthApi]);

  const getAdminSummary = useCallback(
    async (startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminSummary(startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  const getAdminUserTable = useCallback(
    async (params: APIOps["get_admin_user_table_api_admin_user_table_get"]["parameters"]["query"]) => {
      setLoading(true);
      try {
        return await adminAppApi.getAdminUserTable(params);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  const getAdminUserTableDetail = useCallback(
    async (firebase_auth_user_id: string) => {
      try {
        return await adminAppApi.adminUserTableDetail(firebase_auth_user_id);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [adminAppApi],
  );

  const getAdminUserInsight = useCallback(
    async (startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminUserInsight(startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  const getAdminInstagramTable = useCallback(
    async (params: APIOps["get_admin_instagram_table_api_admin_instagram_table_get"]["parameters"]["query"]) => {
      setLoading(true);
      try {
        return await adminAppApi.getAdminInstagramTable(params);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  const getAdminInstagramTableDetail = useCallback(
    async (instagram_account_id: string) => {
      try {
        return await adminAppApi.adminInstagramTableDetail(instagram_account_id);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [adminAppApi],
  );

  const getAdminInstagramInsight = useCallback(
    async (startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminInstagramInsight(startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );
  const getAdminFeedTable = useCallback(
    async (keyword: string, startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminFeedTable(keyword, startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );
  const getAdminFirstFeedTable = useCallback(
    async (keyword: string, startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminFirstFeedTable(keyword, startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );
  const getAdminSignUpQuestionInsight = useCallback(
    async (startDate: string, endDate: string) => {
      setLoading(true);
      try {
        return await adminAppApi.adminSignUpQuestionInsight(startDate, endDate);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  const patchInstagramAccountPlan = useCallback(
    async (
      params: APIOps["update_plan_for_instagram_account_api_admin_instagram_account_plan_patch"]["parameters"]["query"],
    ) => {
      setLoading(true);
      try {
        return await adminAppApi.patchInstagramAccountPlan(params);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [adminAppApi],
  );

  return {
    adminAuthSync,
    adminLogin,
    adminLogout,
    getAdminSummary,
    getAdminUserTable,
    getAdminUserTableDetail,
    getAdminUserInsight,
    getAdminInstagramTable,
    getAdminInstagramTableDetail,
    getAdminInstagramInsight,
    getAdminFeedTable,
    getAdminFirstFeedTable,
    getAdminSignUpQuestionInsight,
    patchInstagramAccountPlan,
  };
};
