import { components } from "@/apis/schema";

import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export type SignupRefererType = {
  id: string;
  firebaseAuthId: string;
  refererCode: string;

  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  gender: components["schemas"]["EnumGender"];
  instagramAccountCount: number;

  createTime: number;
  updateTime: number;
};

export const signupRefererConverter: FirestoreDataConverter<SignupRefererType> = {
  toFirestore(message: SignupRefererType): DocumentData {
    return {
      id: message.id,
      firebaseAuthId: message.firebaseAuthId,
      refererCode: message.refererCode,
      firstName: message.firstName,
      lastName: message.lastName,
      email: message.email,
      companyName: message.companyName,
      gender: message.gender,
      instagramAccountCount: message.instagramAccountCount,
      createTime: message.createTime,
      updateTime: message.updateTime,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): SignupRefererType {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      firebaseAuthId: data.firebaseAuthId,
      refererCode: data.refererCode,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      companyName: data.companyName,
      gender: data.gender,
      instagramAccountCount: data.instagramAccountCount,
      createTime: data.createTime,
      updateTime: data.updateTime,
    };
  },
};
