import { css } from "@emotion/react";

// storybookのためにglobals.cssと合わせなきゃならんぞ。
export const globalStyled = css`
  html {
    font-family: "Roboto", "Noto Sans JP", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    background-color: #fafafa;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  @media all and (min-width: 600px) and (max-width: 1280px) {
    & .MuiPaper-root {
      border-radius: 8px !important;
    }
  }

  @media all and (max-width: 600px) {
    & .MuiPaper-root {
      border-radius: 16px !important;
    }
  }
  & header.MuiPaper-root {
    border-radius: 0 !important;
    position: fixed !important;
    z-index: 999 !important;
  }

  & .MuiList-root {
    display: block;
    overflow: auto;
    height: 100%;
  }
  .sb-show-main.sb-main-padded {
    padding: 0;
  }
  .firebase-emulator-warning {
    display: none;
  }

  // rsuiteの期間指定のUI
  & .rs-picker-menu {
    z-index: 2022 !important;
  }
  & .rs-picker-toggle {
    font-size: 16px !important;
  }
  & .rs-btn-primary {
    color: #ffffff !important;
    background: #40967b !important;
    &:hover {
      color: #ffffff !important;
      background: rgba(64, 150, 123, 0.8) !important;
    }
  }
  & .rs-btn-link {
    color: #1f3730 !important;
    &:hover {
      color: #40967b !important;
    }
    &:focus {
      color: #40967b !important;
    }
  }
  & .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #40967b !important;
  }
  & .rs-calendar-table-cell-in-range:before {
    background: rgba(64, 150, 123, 0.5) !important;
  }
  & .rs-btn-primary:disabled {
    background-color: #40967b !important;
  }
  & .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #40967b !important;
  }
  & .rs-calendar-table-cell {
    &:hover {
      .rs-calendar-table-cell-content {
        color: #40967b !important;
        background: rgba(64, 150, 123, 0.5) !important;
      }
    }
  }
  & .rs-calendar-table-cell-selected-end {
    & .rs-calendar-table-cell-content {
      color: #ffffff !important;
      background: #40967b !important;
    }
  }
  & .rs-calendar-table-cell-selected-start {
    & .rs-calendar-table-cell-content {
      color: #ffffff !important;
      background: #40967b !important;
    }
  }

  & .MuiChip-root {
    &:hover {
      background-color: #40967b !important;
      color: #ffffff !important;

      & .MuiChip-deleteIcon {
        color: #ffffff !important;
      }
    }
  }
`;
