export const ROOT_PATH = "";

// 認証系
export const LOGIN_PATH = `${ROOT_PATH}/auth/login`;
export const SIGNUP_PATH = `${ROOT_PATH}/auth/signup`;
export const PASSWORD_RESET_PATH = `${ROOT_PATH}/auth/password-reset`;

// ダッシュボード
export const DASHBOARD_PATH = `${ROOT_PATH}/dashboard`;

// マイアカウント
export const MYACCOUNT_INSIGHT_PATH = `${ROOT_PATH}/myaccount/insight`;
export const MYACCOUNT_FOLLOWER_TYPE_PATH = `${ROOT_PATH}/myaccount/follower-type`;

// 投稿一覧, ストーリーズ一覧, 初速分析系
export const POST_GENERATOR_PATH = `${ROOT_PATH}/post-generator`;
export const TABLE_FIRST_POST = `${ROOT_PATH}/table/first-feed-post`;
export const TABLE_FEED_PATH = `${ROOT_PATH}/table/feed-post`;
export const TABLE_REEL_FIRST_POST = `${ROOT_PATH}/table/first-reel-post`;
export const TABLE_REEL_PATH = `${ROOT_PATH}/table/reel-post`;
export const TABLE_STORIES_PATH = `${ROOT_PATH}/table/stories`;
export const POST_MEDIA_CALENDAR_PATH = `${ROOT_PATH}/table/post-media-calendar`;

// ハッシュタグ系
export const HASHTAG_PERFORMANCE_PATH = `${ROOT_PATH}/hashtag/performance`;
export const HASHTAG_GROUP_PERFORMANCE_PATH = `${ROOT_PATH}/hashtag/group-performance`;
export const HASHTAG_RECOMMEND_PATH = `${ROOT_PATH}/hashtag/recommend`;

// ベンチマーク系
export const BENCHMARK_SETTINGS_PATH = `${ROOT_PATH}/benchmark/benchmark-settings`;
export const BENCHMARK_INSIGHT_PATH = `${ROOT_PATH}/benchmark/benchmark-insight`;
export const BENCHMARK_FEED_MEDIA_PATH = `${ROOT_PATH}/benchmark/benchmark-feed-media`;
export const BENCHMARK_HASHTAG_PATH = `${ROOT_PATH}/benchmark/benchmark-hashtag`;

// コメント管理
export const MANAGE_COMMENT = `${ROOT_PATH}/comment`;

// ファン
export const FAN_PATH = `${ROOT_PATH}/mention`;

// コンテンツ系
export const POST_RESERVATION_PATH = `${ROOT_PATH}/contents/post-reservation`;
export const CONTENTS_LIBRARY_PATH = `${ROOT_PATH}/contents/contents-library`;

// レポート系
export const REPORT_TEMPLATE = `${ROOT_PATH}/report/template`;
export const REPORT_DOWNLOAD_LIST = `${ROOT_PATH}/report/download-list`;

// マイページ、設定系
export const MYPAGE_PATH = `${ROOT_PATH}/mypage`;
export const MYPAGE_ACCOUNT_INFO_PATH = `${ROOT_PATH}/mypage/account-info`;
export const MYPAGE_INSTAGRAM_ACCOUNT_PATH = `${ROOT_PATH}/mypage/instagram-account`;
export const MYPAGE_RESET_MAIL_PATH = `${ROOT_PATH}/mypage/reset-mailaddress`;
export const MYPAGE_RESET_PASS_PATH = `${ROOT_PATH}/mypage/reset-password`;
export const MYPAGE_FACEBOOK_LINKED_PATH = `${ROOT_PATH}/mypage/facebook-linked`;
export const MYPAGE_PLAN_INFO_PATH = `${ROOT_PATH}/mypage/plan-info`;

// アップグレードプラン
export const PLAN_PATH = `${ROOT_PATH}/plan`;
