import { firestoreDB } from "@/global-state/firebase-settings";
import { loadingAtom } from "@/global-state/jotai-atoms";

import { SignupRefererType, signupRefererConverter } from "@/firestore/SignupRefererModel";
import { doc, setDoc } from "firebase/firestore";
import { useAtom } from "jotai";

const SIGNUP_REFERER_PATH = "signup_referer";

export const useSignupReferer = () => {
  const [, setLoading] = useAtom(loadingAtom);

  const postSlack = async (req: SignupRefererType) => {
    try {
      await fetch("/api/slack/signup-referer", {
        method: "POST",
        mode: "same-origin",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          ...req,
        }),
      });
    } catch (e) {
      console.error(`リファラルのSlack通知送信が失敗しました${e}`);
    }
  };

  const addSignupReferer = async (requestBody: SignupRefererType) => {
    setLoading(true);
    try {
      const docRef = doc(firestoreDB, SIGNUP_REFERER_PATH, requestBody.firebaseAuthId).withConverter(
        signupRefererConverter,
      );
      await setDoc(docRef, requestBody);
      await postSlack(requestBody);
      return requestBody;
    } finally {
      setLoading(false);
    }
  };

  return {
    addSignupReferer,
  };
};
