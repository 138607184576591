import { API_URL } from "@/global-state/firebase-settings";

import axios, { AxiosRequestConfig } from "axios";

export default class BaseApi {
  accessToken: string | null;
  instagramId: string | null;

  constructor(accessToken?: string, instagramId?: string) {
    if (accessToken) this.accessToken = accessToken;
    if (instagramId) this.instagramId = instagramId;
  }

  protected get axiosApiConfig() {
    return {
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : "",
      },
      responseType: "json",
    } as AxiosRequestConfig;
  }

  protected get axiosExcelApiConfig() {
    return {
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : "",
      },
      responseType: "blob",
      dataType: "binary",
    } as AxiosRequestConfig;
  }

  protected get get() {
    return axios.create(this.axiosApiConfig).get;
  }

  protected get post() {
    return axios.create(this.axiosApiConfig).post;
  }

  protected get put() {
    return axios.create(this.axiosApiConfig).put;
  }

  protected get patch() {
    return axios.create(this.axiosApiConfig).patch;
  }

  protected get delete() {
    return axios.create(this.axiosApiConfig).delete;
  }

  protected get excelGet() {
    return axios.create(this.axiosExcelApiConfig).get;
  }
}
