import { getStorage } from "@firebase/storage";
import { Auth, getAuth } from "firebase/auth";
import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BAKET,
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp: firebase.app.App = firebase.apps[0] || firebase.initializeApp(firebaseConfig);

export const firebaseStorage = getStorage(firebaseApp);
export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = firebaseApp.auth();
export const firebaseGetAuth: Auth = getAuth(firebaseApp);
if (process.browser) {
  firebaseApp.analytics();
}

export const API_URL = process.env.API_URL || "";
export const APP_ENV = process.env.APP_ENV || "";
export const FACEBOOK_API_URL = process.env.FACEBOOK_API_URL || "";
export const SAMPLE_IG_ID = process.env.SAMPLE_IG_ID || "";
export const GOOGLE_AUTHENTICATOR_TOKEN = process.env.GOOGLE_AUTHENTICATOR_TOKEN || "";

export default firebase;
