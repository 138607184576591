import { APIOps } from "@/apis/api-config";
import ApiPaths from "@/apis/base-api-paths";

export default class AdminApi extends ApiPaths {
  async adminSummary(
    startDate: string,
    endDate: string,
  ): Promise<APIOps["get_admin_summary_api_admin_summary_get"]["responses"]["200"]["content"]["application/json"]> {
    const params: APIOps["get_admin_summary_api_admin_summary_get"]["parameters"]["query"] = {
      startDate: startDate,
      endDate: endDate,
    };
    return (await this.get(this.toAdminURL(`summary?startDate=${params.startDate}&endDate=${params.endDate}`))).data;
  }
  async getAdminUserTable(
    params: APIOps["get_admin_user_table_api_admin_user_table_get"]["parameters"]["query"],
  ): Promise<
    APIOps["get_admin_user_table_api_admin_user_table_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`${this.toAdminURL(`user-table`)}`, { params: params })).data;
  }
  async adminUserTableDetail(
    firebase_auth_user_id: string,
  ): Promise<
    APIOps["get_admin_user_table_detail_api_admin_user_table_detail_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_user_table_detail_api_admin_user_table_detail_get"]["parameters"]["query"] = {
      firebase_auth_user_id: firebase_auth_user_id,
    };
    return (await this.get(this.toAdminURL(`user-table/detail?firebase_auth_user_id=${params.firebase_auth_user_id}`)))
      .data;
  }
  async adminUserInsight(
    startDate: string,
    endDate: string,
  ): Promise<
    APIOps["get_admin_user_insight_api_admin_user_table_insight_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_user_insight_api_admin_user_table_insight_get"]["parameters"]["query"] = {
      startDate: startDate,
      endDate: endDate,
    };
    return (
      await this.get(this.toAdminURL(`user-table-insight?startDate=${params.startDate}&endDate=${params.endDate}`))
    ).data;
  }
  async getAdminInstagramTable(
    params: APIOps["get_admin_instagram_table_api_admin_instagram_table_get"]["parameters"]["query"],
  ): Promise<
    APIOps["get_admin_instagram_table_api_admin_instagram_table_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`${this.toAdminURL(`instagram-table`)}`, { params: params })).data;
  }
  async adminInstagramTableDetail(
    instagram_account_id: string,
  ): Promise<
    APIOps["get_admin_instagram_table_detail_api_admin_instagram_table_detail_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_instagram_table_detail_api_admin_instagram_table_detail_get"]["parameters"]["query"] =
      {
        instagram_account_id: instagram_account_id,
      };
    return (
      await this.get(this.toAdminURL(`instagram-table/detail?instagram_account_id=${params.instagram_account_id}`))
    ).data;
  }
  async adminInstagramInsight(
    startDate: string,
    endDate: string,
  ): Promise<
    APIOps["get_admin_instagram_insight_api_admin_instagram_table_insight_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_instagram_insight_api_admin_instagram_table_insight_get"]["parameters"]["query"] = {
      startDate: startDate,
      endDate: endDate,
    };
    return (
      await this.get(this.toAdminURL(`instagram-table-insight?startDate=${params.startDate}&endDate=${params.endDate}`))
    ).data;
  }
  async adminFeedTable(
    keyword: string,
    startDate: string,
    endDate: string,
  ): Promise<
    APIOps["get_admin_feed_media_api_admin_feed_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_feed_media_api_admin_feed_list_get"]["parameters"]["query"] = {
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
    };
    return (
      await this.get(
        this.toAdminURL(`feed/list?keyword=${params.keyword}&startDate=${params.startDate}&endDate=${params.endDate}`),
      )
    ).data;
  }
  async adminFirstFeedTable(
    keyword: string,
    startDate: string,
    endDate: string,
  ): Promise<
    APIOps["get_admin_first_feed_media_api_admin_first_feed_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    const params: APIOps["get_admin_first_feed_media_api_admin_first_feed_list_get"]["parameters"]["query"] = {
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
    };
    return (
      await this.get(
        this.toAdminURL(
          `first-feed/list?keyword=${params.keyword}&startDate=${params.startDate}&endDate=${params.endDate}`,
        ),
      )
    ).data;
  }
  async adminSignUpQuestionInsight(
    startDate: string,
    endDate: string,
  ): Promise<
    APIOps["update_all_user_api_admin_question_signup_answer_get"]["responses"]["200"]["content"]["application/json"]
  > {
    // const params: APIOps["update_all_user_api_admin_question_signup_answer_get"]["parameters"]["query"] = {
    //   startDate: startDate,
    //   endDate: endDate,
    // };
    return (await this.get(this.toAdminURL(`question/signup-answer`))).data;
  }

  async patchInstagramAccountPlan(
    params: APIOps["update_plan_for_instagram_account_api_admin_instagram_account_plan_patch"]["parameters"]["query"],
  ): Promise<
    APIOps["update_plan_for_instagram_account_api_admin_instagram_account_plan_patch"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.patch(
        `${this.toAdminURL(
          `instagram-account/plan?instagram_account_id=${params.instagram_account_id}&stripe_subscription_id=${params.stripe_subscription_id}`,
        )}`,
      )
    ).data;
  }
}
