import { css } from "@emotion/react";
import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

import { loadingAtom } from "@/global-state/jotai-atoms";

import { useAtom } from "jotai";

type Props = {
  children: React.ReactNode;
  open?: boolean;
};

const BackdropProgress: React.FC<Props> = (props) => {
  const [loading] = useAtom(loadingAtom);
  return (
    <>
      <Backdrop css={backdropStyles} open={props.open || loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.children}
    </>
  );
};

export default BackdropProgress;

const backdropStyles = css`
  z-index: 10000 !important;
  color: #ffffff;
  position: fixed;
`;
